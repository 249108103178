.navbar-button {
    text-transform: none !important;
    margin: 0 8px !important;
    font-weight: 500 !important;
  }
  
  .nav-link {
    color: white;
    text-decoration: none;
    padding: 8px 16px;
    border-radius: 4px;
    transition: all 0.3s ease;
    font-weight: 500;
  }
  
  .nav-link:hover {
    background-color: rgba(255, 255, 255, 0.1);
    transform: translateY(-2px);
  }
  
  .nav-link.active {
    color: #1a237e;
    background-color: rgba(255, 255, 255, 0.2);
  }
  
  .free-trial-button {
    background: linear-gradient(45deg, #f50057, #ff4081) !important;
    color: white !important;
    padding: 8px 24px !important;
    border-radius: 25px !important;
    font-weight: bold !important;
    text-transform: none !important;
    transition: all 0.3s ease !important;
    box-shadow: 0 2px 8px rgba(245, 0, 87, 0.3) !important;
  }
  
  .free-trial-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(245, 0, 87, 0.4) !important;
  }
  
  .auth-button {
    border: 2px solid white !important;
    color: white !important;
    padding: 6px 20px !important;
    border-radius: 20px !important;
    text-transform: none !important;
    transition: all 0.3s ease !important;
    margin: 0 8px !important;
    font-weight: 500 !important;
  }
  
  .auth-button:hover {
    background-color: white !important;
    color: #48CFCB !important;
    transform: translateY(-2px);
  }
  
  .logo-container {
    display: flex;
    align-items: center;
    gap: 16px;
  }
  
  .logo-image {
    max-height: 40px;
    transition: transform 0.3s ease;
  }
  
  .logo-image:hover {
    transform: scale(1.05);
  }