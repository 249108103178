.break-effect {
    position: fixed;
    pointer-events: none;
    width: 100px;
    height: 100px;
    background: radial-gradient(circle, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0) 70%);
    animation: break 0.5s ease-out forwards;
  }
  
  @keyframes break {
    0% {
      transform: scale(0);
      opacity: 1;
    }
    100% {
      transform: scale(2);
      opacity: 0;
    }
  }