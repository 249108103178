.game-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  font-family: Arial, sans-serif;
}

h1 {
  color: #333;
  margin-bottom: 20px;
}

.word-list {
  margin-bottom: 20px;
  text-align: center;
}

.words {
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
  justify-content: center;
  font-family:"comic sans ms"; 
}

.words span {
  padding: 8px 15px;
  background-color: red;
  color: white;
  border-radius: 15px;
  font-weight: bold;
}

.words span.found {
  background-color: #4CAF50;
  color: white;
  text-decoration: line-through;
}

.grid {
  background: white;
  border: 2px solid #333;
  border-radius: 8px;
  padding: 10px;
  user-select: none;
}

.row {
  display: flex;
}

.cell {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ccc;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.2s;
}

.cell:hover {
  background-color: #f0f0f0;
}

.cell.selected {
  background-color: #2196F3;
  color: white;
}

.cell.found {
  background-color: #4CAF50;
  color: white;
}

.win-message {
  margin-top: 20px;
  padding: 15px 30px;
  background-color: #4CAF50;
  color: white;
  border-radius: 25px;
  font-size: 1.2em;
  font-weight: bold;
  animation: pop 0.3s ease-out;
}

@keyframes pop {
  0% {
    transform: scale(0.8);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}