

.mode-selection,
.player-setup {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  margin-top: 50px;
}

.game-container {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.score-board {
  display: flex;
  justify-content: center;
  gap: 40px;
  padding: 20px;
}

.player-card {
  padding: 20px;
  width: 200px;
  text-align: center;
  transition: transform 0.3s ease;
}

.player-card.current-turn.player1 {
  background-color: #90EE90 !important;
}

.player-card.current-turn.player2 {
  background-color: #87CEEB !important;
}

.player-card.current-turn {
  transform: scale(1.05);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
}

.cards-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 20px;
  margin-top: 10px;
}

.game-card {
  cursor: pointer;
  transition: transform 0.3s ease;
  aspect-ratio: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
}

.game-card:hover {
  transform: scale(1.05);
}

.game-card.answered-player1 {
  background-color: #90EE90;
}

.game-card.answered-player2 {
  background-color: #87CEEB;
}

.submit-result-btn {
  margin-top: 5px;
  align-self: center;
}

.review-answer {
  background-color: #f5f5f5;
  border-radius: 4px;
  margin-top: 16px;
}

@media (max-width: 768px) {
  .cards-grid {
    grid-template-columns: repeat(3, 1fr);
  }
  
  .score-board {
    flex-direction: column;
    align-items: center;
  }
}

@media (max-width: 480px) {
  .cards-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}