.app-container-cardclash {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  background-image: url("/public/cards/cardclash_bg.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  padding: 20px;
  color: white;
  z-index: 1; /* Ensures content stays on top */
}

.app-container-cardclash::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.327); /* Adjust darkness level */
  z-index: -1; /* Sends the overlay behind content */
}


  
  .main-menu {
    text-align: center;
  }
  
  .cards-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* 4 cards per row */
    gap: 20px;
    margin-bottom: 40px;
    padding: 20px;
    margin: 20px 0;
  }
  @media (max-width: 768px) {
    .cards-grid {
      grid-template-columns: repeat(1, 1fr); /* 2 cards per row on smaller screens */
    }
  }
  .score-display {
    position: fixed;
    top: 10px;
    right: 20px;
    background: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 10px 20px;
    border-radius: 20px;
    font-size: 1.2em;
    z-index: 1000;
  }
  
  .start-button-cardclash {
    position: fixed; /* Keeps the button fixed on screen */
    top: 70px; /* Adjust the distance from the top */
    right: 20px; /* Adjust the distance from the right */
    z-index: 1000; /* Keeps it above other content */
    background-color: #ff5722;
    color: white;
    padding: 12px 25px;
    border: none;
    cursor: pointer;
    font-size: 1rem;
    border-radius: 15px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  
  
  .start-button-cardclash:hover {
    transform: scale(1.1);
  }
  
  .warning-text {
    color: #f44336;
    font-size: 1.2em;
    margin: 0 0;
  }
  
  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  
  .app-container {
    animation: fadeIn 0.5s ease;
  }