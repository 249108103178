.health-container {
    width: 300px;
    margin: 10px;
  }
  
  .health-label {
    font-size: 1.2em;
    margin-bottom: 5px;
    color: #fff;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  }
  
  .health-bar {
    height: 20px;
    background: #333;
    border: 2px solid #000;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
  }
  
  .health-fill {
    height: 100%;
    background: linear-gradient(to right, #4CAF50, #8BC34A);
    transition: width 0.5s ease-out;
  }
  
  .health-fill.critical {
    background: linear-gradient(to right, #f44336, #ff9800);
    animation: pulse 1s infinite;
  }
  
  @keyframes pulse {
    0% { opacity: 1; }
    50% { opacity: 0.7; }
    100% { opacity: 1; }
  }