.vocab-review {
  padding: 10px;
  overflow: hidden;
  background-color: #ffe5b4;
}

.controls {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin: 10px 0;
}

.controls button {
  padding: 10px 10px;
  font-size: 20px;
  cursor: pointer;
}

.timeline {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 400px;
  margin: 20px 0;
  perspective: 1000px;
}

.vocab-card {
  position: absolute;
  width: 300px;
  min-height: 300px;
  background: #fff8e1;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.5s ease;
  cursor: pointer;
  transform-origin: center center;
}

.vocab-card.selected {
  z-index: 10;
  transform: translateX(0) scale(1);
  opacity: 1;
}

.vocab-card.left {
  transform: translateX(-120%) scale(0.8);
  opacity: 0.7;
}

.vocab-card.right {
  transform: translateX(120%) scale(0.8);
  opacity: 0.7;
}

.vocab-card:not(.selected):not(.left):not(.right) {
  opacity: 0;
  pointer-events: none;
}

.card-content {
  padding: 20px;
  position: relative;
}

.details {
  margin-top: 5px;
}

.details p {
  margin: 10px 0;
  font-size: 14px;
}

.checkmark {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #4CAF50;
  color: white;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}

.review-button {
  display: block;
  margin: 20px auto;
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.navigation-buttons{
  display: flex;
  justify-content: center;
  gap: 20px;
  margin: 10px 0;
}
.review-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.instructions {
  text-align: center;
  color: #666;
}

@media (max-width: 768px) {
  .vocab-card {
    width: 280px;
  }
  
  .vocab-card.left {
    transform: translateX(-110%) scale(0.8);
  }
  
  .vocab-card.right {
    transform: translateX(110%) scale(0.8);
  }
  .instructions {
display: none;
  }
}

@media (max-width: 480px) {
  .vocab-card {
    width: 260px;
  }
  
  .vocab-card.left {
    transform: translateX(-105%) scale(0.8);
  }
  
  .vocab-card.right {
    transform: translateX(105%) scale(0.8);
  }
}


.progress-container {
  padding: 0.5 rem;
  text-align: center;
  /* margin-top:-10 px; */
}

.rocket-container-vocab {
  position: relative;
  margin: 40px auto;
  width: 80%;
  height: 60px;
}

.rocket-vocab {
  position: absolute;
  font-size: 2.5rem;
  transform: translateX(-50%) translateY(-40%);
  transition: left 0.5s ease-out;
  z-index: 2;
  animation: bounce 2s infinite;
}

.progress-track {
  position: relative;
  background: white;
  height: 20px;
  border-radius: 10px;
  overflow: hidden;
}

.progress-fill {
  position: absolute;
  height: 100%;
  background: linear-gradient(90deg, #FFD700, #FFA500);
  transition: width 0.5s ease-out;
  border-radius: 10px;
}


.progress-text {
  font-size: 1.5rem;
  margin-top: -80px;
  color: #333;
}

.progress-number {
  font-size: 2rem;
  font-weight: bold;
  color: #FF6B6B;
}

.progress-divider {
  margin: 0 10px;
  color: #666;
}

.progress-message {
  display: block;
  margin-top: 0.5rem;
  color: #4A90E2;
  font-size: 1.2rem;
}

@keyframes bounce {
  0%, 100% { transform: translateX(-50%) translateY(-40%); }
  50% { transform: translateX(-50%) translateY(-60%); }
}
/* Add these styles to your existing CSS */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup {
  background: white;
  padding: 2rem;
  border-radius: 10px;
  text-align: center;
  animation: popupFadeIn 0.3s ease-out;
}

.popup h3 {
  color: #333;
  margin-bottom: 1rem;
}

.popup p {
  color: #666;
  margin-bottom: 0.5rem;
}

@keyframes popupFadeIn {
  from {
    opacity: 0;
    transform: scale(0.8);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}