/* General styles for fun look */
.MuiDialog-paper {
    animation: dialog-appear 0.5s ease-in-out;
    box-shadow: 0px 4px 10px rgba(31, 214, 208, 0.3);
  }
  
  @keyframes dialog-appear {
    0% {
      transform: scale(0.9);
      opacity: 0.8;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
  
 
  @keyframes button-dance {
    0%,
    100% {
      transform: translateY(0);
      background-color: #1fd6d0;
    }
    50% {
      transform: translateY(-4px);
      background-color: #119e9a;
    }
  }
  
  /* Background Color Changes */
  .MuiDialogContent-root {
    border-radius: 15px;
    box-shadow: inset 0px 0px 15px rgba(31, 214, 208, 0.2);
  }
  