.game-board {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.game-info {
  text-align: center;
  margin-top: -50px;
  /* margin-bottom: 20px; */
}

.battle-area {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 20px 0;
}

.player-area,
.computer-area {
  flex: 1;
  display: flex;
  justify-content: center;
  min-height: 250px;
}

.vs-display {
  font-size: 2em;
  font-weight: bold;
  color: #ff9900;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

.available-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  margin: 20px 0;
  padding: 20px;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.battle-button,
.attribute-button,
.return-button {
  padding: 10px 20px;
  font-size: 1.2em;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background: #4CAF50;
  color: white;
  margin-top: -20px;
  transition: all 0.3s ease;
}

.battle-button:hover,
.attribute-button:hover,
.return-button:hover {
  transform: scale(1.05);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.attribute-selection {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin: 20px 0;
}

.round-result {
  text-align: center;
  font-size: 2em;
  font-weight: bold;
  /* margin: 20px 0; */
  animation: fadeIn 0.5s ease;
}

.round-result.win {
  color: #4CAF50;
}

.round-result.lose {
  color: #f44336;
}

.round-result.draw {
  color: #ff9900;
}

.game-results {
  text-align: center;
  padding: 20px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
}

.results-summary {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 20px 0;
}

.result-item {
  padding: 10px;
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.1);
}

.result-item.win {
  color: #4CAF50;
}

.result-item.lose {
  color: #f44336;
}

.result-item.draw {
  color: #ff9900;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}