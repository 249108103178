.card {
  width: 200px !important;
  height: 280px !important;
  perspective: 1000px;
  margin: 10px;
  cursor: pointer;
  position: relative;
  }
  .available-cards{
    margin-right: 10px;
  }
  .card-inner {
    position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s ease-in-out;
  transform-style: preserve-3d;
  }
  
  .card.flipped .card-inner {
    transform: rotateY(180deg);
  }
  
  .card-front,
  .card-back {
    position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(135deg, #2a3f54, #1a2634);
  color: white;
  }
  
  .card-back {
    transform: rotateY(180deg);
    background: linear-gradient(135deg, #1a2634, #2a3f54);
  }
  
  .card.locked {
    opacity: 0.7;
    cursor: not-allowed;
  }
  
  .card.locked .card-front {
    background: linear-gradient(135deg, #4a4a4a, #2a2a2a);
  }
  
  .card-image {
    width: 100%;
    height: 100%;
  }
  
  .card-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .stat-name {
    position: absolute;
    bottom: 0px;
    left: 0;
    right: 0;
    border-radius: 15px;
    background: rgba(0, 0, 0, 0.8);
    color: #fff !important;
    padding: 5px;
    font-size: 1.2rem;
    text-align: center;
  }
  
  .card-tooltip {
    position: absolute;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(0, 0, 0, 0.9);
    padding: 10px;
    border-radius: 5px;
    width: 90%;
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  
  .card-tooltip:hover {
    opacity: 1;
  }
  
  .card-stats {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  
  .card.selected {
    transform: translateY(-10px);
    box-shadow: 0 0 20px rgba(255, 215, 0, 0.5);
  }
  
  .lock-info {
    font-size: 0.8em;
    color: #ff9999;
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
  }
  
  @keyframes hover {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-5px);
    }
    100% {
      transform: translateY(0);
    }
  }
  
  .card:hover:not(.locked) {
    animation: hover 1s ease infinite;
  }
  .card-stats {
    position: absolute;
    /* bottom: 20px; */
    left: 50%;
    transform: translateX(-50%);
    background: rgba(0, 0, 0, 0.9);
    color: white;
    border-radius: 5px;
    z-index: 11;
    width: 100%;
    height: auto;
    transition: opacity 0.3s ease, transform 0.3s ease;
  }
  
  .card:hover .card-stats {
    opacity: 1;
    transform: translateX(-50%) translateY(-10px);
  }