.battle-comparison {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
    text-align: center;
    background: rgba(0, 0, 0, 0.9);
    color: white;
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.5);
  }
  
  .comparison-values {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 20px;
  }
  
  .player-value,
  .computer-value {
    font-size: 2em;
    font-weight: bold;
    padding: 10px 20px;
    border-radius: 5px;
    min-width: 80px;
  }
  
  .attribute-name {
    font-size: 1.5em;
    color: #ff9900;
    text-shadow: 0 0 5px rgba(255, 153, 0, 0.5);
  }
  
  .winner {
    color: #4CAF50;
    animation: pulse 0.5s ease infinite;
  }
  
  .result-message {
    font-size: 2.5em;
    font-weight: bold;
    margin: 20px 0;
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
    animation: fadeInScale 0.5s ease;
    margin-bottom: 20px;
  }
  
  .result-message.win {
    color: #4CAF50;
  }
  
  .result-message.lose {
    color: #f44336;
  }
  
  .result-message.draw {
    color: #ff9900;
  }
  
  .winning-card {
    animation: zoomIn 0.5s ease forwards;
    transform: scale(0);
    display: flex;
    justify-content: center;
  }
  
  
  @keyframes pulse {
    0% { transform: scale(1); }
    50% { transform: scale(1.1); }
    100% { transform: scale(1); }
  }
  
  @keyframes zoomIn {
    from { transform: scale(0); }
    to { transform: scale(1); }
  }
  
  @keyframes fadeInScale {
    from { 
      opacity: 0;
      transform: scale(0.5);
    }
    to { 
      opacity: 1;
      transform: scale(1);
    }
  }