.race-track {
    position: relative;
    width: 100%;
    height: 150px;
    margin-bottom: 50px;
  }
  
  .track {
    position: relative;
    height: 50px;
    margin: 10px 0;
    background-color: #514f4f;
    border-radius: 5px;
    overflow: hidden;
  }
  
  .car {
    position: absolute;
    bottom: 10px;
    width: 60px;
    height: auto;
    transition: left 0.7s ease; /* Smooth movement */
  }
  
  .player-car {
    background-color: red;
  }
  
  .computer-car {
    background-color: blue;
  }
  
  .finish-line {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    font-size: 24px;
    color: #000;
  }
  