.battle-area {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 20px 0;
    padding: 20px;
    background: rgba(0, 0, 0, 0.2)!important;
    border-radius: 15px;
  }
  
  .player-section,
  .computer-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
  
  .vs-display {
    font-size: 2.5em;
    font-weight: bold;
    color: #ff9900;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
    animation: glow 1.5s ease-in-out infinite alternate;
  }
  
  @keyframes glow {
    from {
      text-shadow: 0 0 5px #ff9900, 0 0 10px #ff9900;
    }
    to {
      text-shadow: 0 0 10px #ff9900, 0 0 20px #ff9900;
    }
  }