* {
  box-sizing: border-box;
}

.game-container-rocket{
  position: relative;
  height: 100vh;
  width: 95vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('https://wallpapercat.com/w/full/3/3/d/24274-1920x1200-desktop-hd-outer-space-wallpaper-image.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden; /* Prevent overflow */
}

.rocket {
  position: absolute;
  left: 528px;
  font-size: 3rem;
  transition: bottom 1s ease-in-out;
  z-index: 10;
}
.wrong-popup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(255, 59, 59, 0.9);
  color: white;
  padding: 1rem 2rem;
  border-radius: 8px;
  font-size: 1.2rem;
  z-index: 12;
  animation: fadeInOut 1.5s ease-in-out forwards;
}
.question-container-rocket {
  background: rgba(255, 255, 255, 0.1);
  padding: 2rem;
  border-radius: 15px;
  text-align: center;
  backdrop-filter: blur(10px);
  transition: opacity 0.3s ease-in-out;
  width: 90%;
  max-width: 600px;
  margin: 0 20px;
}

.question-container-rocket.fade-out {
  opacity: 0;
}

.wordDisplay {
  font-size: clamp(1.8rem, 5vw, 2.5rem);
  margin: 1rem 0;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.options {
  display: flex;
  gap: 1rem;
  margin: 2rem 0;
  justify-content: center;
  flex-wrap: wrap; /* Allow wrapping on smaller screens */
}

.option-button {
  padding: 0.8rem 1.5rem;
  font-size: clamp(1rem, 3vw, 1.2rem);
  border: none;
  border-radius: 25px;
  background: #4a90e2;
  color: white;
  cursor: pointer;
  transition: transform 0.2s, background 0.2s;
  min-width: 120px;
}

.option-button:hover {
  transform: scale(1.05);
  background: #357abd;
}

.restart-button {
  margin-top: 1.5rem;
  padding: 0.8rem 1.5rem;
  font-size: clamp(1rem, 3vw, 1.2rem);
  border: none;
  border-radius: 25px;
  background: #50c878;
  color: white;
  cursor: pointer;
  transition: transform 0.2s, background 0.2s;
}

.restart-button:hover {
  transform: scale(1.05);
  background: #3cb371;
}

.progress {
  color: #fff;
  font-size: 0.9rem;
  opacity: 0.8;
  margin-top: 1rem;
}

.completion-message {
  text-align: center;
  color: white;
  background: rgba(0, 0, 0, 0.7);;
  padding: 2rem;
  border-radius: 15px;
  backdrop-filter: blur(10px);
  width: 90%;
  max-width: 600px;
  margin: 0 20px;
}

.completion-message h2 {
  font-size: clamp(1.5rem, 4vw, 2rem);
  margin-bottom: 1rem;
}

@media (max-width: 600px) {
  .game-container {
    padding: 0 10px; /* Add padding to prevent content from touching edges */
  }

  .question-container {
    padding: 1rem;
    width: 100%;
    max-width: 100%;
  }

  .word {
    font-size: clamp(1.5rem, 4vw, 2rem); /* Adjust font size for smaller screens */
  }

  .options {
    flex-direction: column;
    width: 100%;
    gap: 0.8rem;
  }

  .option-button {
    width: 100%;
    min-width: unset;
  }

  .restart-button {
    width: 100%;
    min-width: unset;
  }

  .completion-message {
    padding: 1.5rem;
  }
}
/* Ensure the rocket doesn't overlap the buttons in mobile view */
@media (max-width: 600px) {
  .rocket {
    bottom: 10%; /* Adjust position to move the rocket up */
    font-size: 2rem; /* Scale down the rocket's size in smaller view */
    left: 50px;
  }
  .wrong-popup {
    font-size: 1rem;
  }
}

