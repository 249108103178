/* Game Container */
.game-container-wheel {
    max-width: 800px;
    margin: 0 auto;
    padding: 10px;
    text-align: center;
  }
  
  .game-container-wheel h1 {
    font-size: clamp(24px, 5vw, 32px);
    margin-bottom: 20px;
  }
  
  .player-setup {
    margin: 20px 0;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
  }
  
  .player-setup input {
    padding: 8px 12px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
    width: 100%;
    max-width: 200px;
  }
  
  /* Players Section */
  .players-section {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-bottom: 30px;
    flex-wrap: wrap;
  }
  
  .player {
    padding: 10px 20px;
    border: 2px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
    min-width: 120px;
  }
  
  .player.active {
    border-color: #4CAF50;
    background-color: #4CAF50;
    color: white;
  }
  
  .player input {
    border: none;
    padding: 5px;
    font-size: 16px;
    width: 100px;
  }
  
  .player-score {
    display: block;
    font-size: 14px;
    margin-top: 5px;
    color: #666;
  }
  
  .active .player-score {
    color: white;
  }
  
  /* Wheel Container */
  .wheel-container {
    position: relative;
    width: min(400px, 90vw);
    height: min(400px, 90vw);
    margin: 0 auto;
    margin-top: 40px;
  }
  
  .wheel {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    position: relative;
    transition: transform 3s cubic-bezier(0.17, 0.67, 0.12, 0.99);
    transform: rotate(0deg);
    overflow: visible;
  }
  
  .wheel-section {
    position: absolute;
    width: 50%;
    height: 50%;
    transform-origin: 100% 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    cursor: default;
    clip-path: polygon(100% 100%, 0 100%, 50% 0);
    z-index: 1;
  }
  
  .wheel-section span {
    position: absolute;
    bottom: 60%;
    left: 35%;
    transform-origin: 50% 100%;
    white-space: nowrap;
    font-size: clamp(10px, 3vw, 14px);
    color: white;
    text-shadow: 1px 1px 2px rgba(0,0,0,0.8);
    z-index: 2;
  }
  
  /* Color each section differently */
  .wheel-section:nth-child(1) { 
    background-color: #FF6B6B;
    transform: rotate(0deg);
  }
  .wheel-section:nth-child(2) { 
    background-color: #4ECDC4;
    transform: rotate(60deg);
  }
  .wheel-section:nth-child(3) { 
    background-color: #45B7D1;
    transform: rotate(120deg);
  }
  .wheel-section:nth-child(4) { 
    background-color: #96CEB4;
    transform: rotate(180deg);
  }
  .wheel-section:nth-child(5) { 
    background-color: #FFEEAD;
    transform: rotate(240deg);
  }
  .wheel-section:nth-child(6) { 
    background-color: #D4A5A5;
    transform: rotate(300deg);
  }
  
  .wheel-section:nth-child(1) span { transform: rotate(0deg); }
  .wheel-section:nth-child(2) span { transform: rotate(-60deg); }
  .wheel-section:nth-child(3) span { transform: rotate(-120deg); }
  .wheel-section:nth-child(4) span { transform: rotate(-180deg); }
  .wheel-section:nth-child(5) span { transform: rotate(-240deg); }
  .wheel-section:nth-child(6) span { transform: rotate(-300deg); }
  
  .spin-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: clamp(15px, 4vw, 20px) clamp(30px, 8vw, 40px);
    font-size: clamp(18px, 5vw, 24px);
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    z-index: 10;
  }
  
  .spin-button:hover {
    background-color: #45a049;
  }
  
  .spin-button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
  
  /* Arrow Indicator */
  .wheel-arrow {
    position: absolute;
    top: -20px;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: clamp(15px, 4vw, 20px) solid transparent;
    border-right: clamp(15px, 4vw, 20px) solid transparent;
    border-top: clamp(30px, 8vw, 40px) solid #333;
    z-index: 20;
  }
  
  /* Question Container */
  .question-container {
    margin-top: 30px;
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 10px;
  }
  
  .question-container h3 {
    color: #333;
    margin-bottom: 10px;
    font-size: clamp(18px, 4vw, 24px);
  }
  
  .question-container p {
    font-size: clamp(16px, 3.5vw, 18px);
    color: #666;
    margin-bottom: 20px;
    padding: 0 10px;
  }
  
  /* Options Container */
  .options-container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 15px;
    margin-top: 20px;
    padding: 0 20px;
  }
  
  @media (min-width: 640px) {
    .options-container {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  .option-button {
    padding: 15px;
    font-size: clamp(14px, 3vw, 16px);
    background-color: #fff;
    border: 2px solid #4CAF50;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
    color: #333;
    white-space: pre-wrap;
    word-break: break-word;
    line-height: 1.4;
  }
  
  .option-button:hover:not(:disabled) {
    background-color: #4CAF50;
    color: white;
  }
  
  .option-button.selected {
    background-color: #4CAF50;
    color: white;
  }
  
  .option-button:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }
  
  /* Popup Styles */
  .popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    padding: 20px;
  }
  
  .popup-content {
    background-color: white;
    padding: clamp(20px, 5vw, 30px);
    border-radius: 10px;
    max-width: 500px;
    width: 90%;
    text-align: center;
  }
  
  .popup-title {
    font-size: clamp(20px, 5vw, 24px);
    margin-bottom: 15px;
    color: #333;
  }
  
  .popup-title.correct {
    color: #4CAF50;
  }
  
  .popup-title.wrong {
    color: #f44336;
  }
  
  .popup-answer {
    font-size: clamp(16px, 4vw, 18px);
    margin-bottom: 20px;
    color: #666;
    padding: 0 10px;
  }
  
  .next-button {
    padding: clamp(8px, 2vw, 10px) clamp(20px, 5vw, 30px);
    font-size: clamp(14px, 3.5vw, 16px);
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .next-button:hover {
    background-color: #45a049;
  }
  
  .rounds-info {
    margin-bottom: 20px;
    font-size: clamp(14px, 3.5vw, 16px);
    color: #666;
  }
  
  .game-over {
    font-size: clamp(24px, 6vw, 28px);
    color: #333;
    margin-bottom: 20px;
  }
  
  .winner-info {
    font-size: clamp(20px, 5vw, 24px);
    color: #4CAF50;
    margin-bottom: 30px;
  }
  
  .start-button {
    padding: clamp(8px, 2vw, 10px) clamp(20px, 5vw, 30px);
    font-size: clamp(16px, 4vw, 18px);
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
  }
  
  .start-button:hover {
    background-color: #45a049;
  }