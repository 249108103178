.flashing-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  /* background-color: #48CFCB; */
  overflow: hidden;
  text-align: center; 
}
.flashingWord-instructions {
  position: absolute;
  top: 10px;
  left: 50%;
  color: #444;
  transform: translateX(-50%);
  font-size: 1.2em;
  padding: 10px;
  border-radius: 5px;
}
.word {
  display: grid;
  font-size: 5vw;
  font-weight: bold;
  color: #48CFCB;

  opacity: 0;
  transform: scale(0.5);
  position: absolute;
  text-align: center; 
}

.word.visible {
  animation: zoomInFade 0.5s ease-in-out forwards;
}

.word.exit {
  animation: zoomOutFade 0.5s ease-in-out forwards;
}

@keyframes zoomOutFade {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(1.5);
  }
}

@keyframes zoomInFade {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}


.correct {
  color: #4CAF50; 
}

.incorrect {
  color: #FF5252; 
}


@media (max-width: 480px) {
  .word {
    font-size: 12vw; 
  }
  .flashingWord-instructions{
    position: absolute;
    top: 60px;
  }
}
@media (max-width: 700) {
 
  .flashingWord-instructions{
    position: absolute;
    top: 60px;
  }
}
