   .game-info {
    display: flex;
    justify-content: space-around;
    /* margin: 20px 0; */
    font-size: 35px;
    gap: 15px;
   /* flex-direction: column; */
  }

  
  .scrambled-word {
    font-size: 2.5em;
    font-weight: bold;
    letter-spacing: 5px;
    margin: 20px 0;
    color: #2c3e50;
  }
  .timer-section , .score-section{
    margin-top: -10px;
  }
  .guess-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: center;
  }
  
  .Unscramble-input {
    padding: 10px 15px;
    font-size: 1.2em;
    border: 2px solid #3498db;
    border-radius: 5px;
    width: 80%;
    max-width: 300px;
  }
  
  .Unscramble-button {
    padding: 12px 25px;
    font-size: 1.1em;
    background-color: #3498db;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .Unscramble-button:hover {
    background-color: #2980b9;
  }
  
  .Unscramble-:disabled {
    background-color: #bdc3c7;
    cursor: not-allowed;
  }
  
  .message {
    margin-top: 20px;
    font-size: 1.2em;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .restart-button {
    background-color: #2ecc71;
    margin-top: 20px;
  }
  
  .restart-button:hover {
    background-color: #27ae60;
  }
  .start-button,
.restart-button,
.skip-button {
  margin-top: 10px;
  padding: 10px 20px;
  font-size: 16px;
  background-color: #4caf50;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.start-button:hover,
.restart-button:hover,
.skip-button:hover {
  background-color: #45a049;
}
